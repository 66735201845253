import request from '@/utils/request'

export function queryMiniAd(parameter) {
  return request({
    url: '/integral/advertise',
    method: 'post',
    data: parameter
  })
}

export function putMiniAd(parameter) {
  return request({
    url: '/integral/advertise',
    method: 'put',
    data: parameter
  })
}

export function getMiniAd(parameter) {
  return request({
    url: '/integral/advertise' ,
    method: 'get',
    params: parameter
  })
}

export function deleteMiniAd(parameter) {
  return request({
    url: '/integral/advertise/delete/' + parameter.id,
    method: 'delete',
    // params: parameter
  })
}
