<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card :body-style="{padding: '24px 32px'}" :bordered='false'>
      <a-form-model ref='form' :model='form' :rules='rules' :labelCol='labelCol' :wrapperCol='wrapperCol'>
        <a-form-model-item label='广告标题' prop='title'>
          <a-input v-model='form.title' />
        </a-form-model-item>
        <a-form-model-item label='广告封面' prop='cover'>
          <a-upload
            list-type='picture-card'
            class='avatar-uploader'
            :show-upload-list='false'
            :customRequest='handleUpload'>
            <img v-if='form.cover' :src='form.cover' alt='cover' width='375px' />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class='ant-upload-text'>上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label='广告状态' prop='status'>
          <a-radio-group v-model='form.status'>
            <a-radio :value='0'>下架</a-radio>
            <a-radio :value='1'>上架</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label='广告类型' prop='type'>
          <a-select v-model="form.type" placeholder="请选择" @change='form.production = ""'>
            <a-select-option :value="1">打开链接</a-select-option>
            <a-select-option :value="2">普通商品</a-select-option>
            <a-select-option :value="3">内容编辑</a-select-option>
            <a-select-option :value="4">分享广告</a-select-option>
            <a-select-option :value="5">试用商品</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item label='排序优先级' prop='priority' help='数字越大优先级越高'>
          <a-input-number v-model='form.priority' />
        </a-form-model-item> -->
        <a-form-model-item label='链接地址' prop='link' v-if="form.type == 1">
          <a-input v-model='form.link' placeholder='请填写链接地址' />
        </a-form-model-item>
        <a-form-model-item label='普通商品' prop='production' v-if="form.type == 2">
          <a-select show-search :value='form.production'
            :default-active-first-option='false'
            :filter-option='false'
            placeholder="请选择"
            @dropdownVisibleChange='handleSearch'
            @change='handleChange'
            @search='handleSearch'>
            <a-select-option v-for='item in prodList' :key='item.id'>
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <template v-if="form.type == 3">
          <a-form-model-item label='内容详情' prop='contentRichText'>
            <quill-editor class='editor' ref='myTextEditor'
                          prefix='advertise/html/'
                          :value='form.contentRichText'
                          @change='handleQuillChange'>
            </quill-editor>
          </a-form-model-item>
          <a-form-model-item label='内容链接' prop='contentRichTextUrl'>
            <a-input :readOnly='true' v-model='form.contentRichTextUrl' placeholder='系统生成无需手动填写' />
          </a-form-model-item>
        </template>
        <a-form-model-item :wrapper-col=' { lg: { span: 15 ,offset: 7}, sm: { span: 17 ,offset: 7} }'>
          <a-button @click='handleReset'>
            重置
          </a-button>
          <a-divider type='vertical' />
          <a-button @click='handleCancel'>
            取消
          </a-button>
          <a-divider type='vertical' />
          <a-button type='primary' @click='handleSubmit'>
            提交
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import fs from 'string-to-file-stream'
import { QuillEditor } from '@/components'
import client from '@/config/oss.config.js'
import { getMiniAd, putMiniAd } from '@/api/advertise'
import { queryProductions } from '@/api/production'
import { combine } from '@/config/quill.config'


export default {
  name: 'MiniAdvertiseEdit',
  data() {
    return {
      labelCol: { lg: { span: 7 }, sm: { span: 7 } },
      wrapperCol: { lg: { span: 15 }, sm: { span: 17 } },
      uuid: this.$uuid.v4(),
      loading: false,
      prodList: [],
      form: {
        id: '',
        cover: '',
        title: '',
        status: '',
        type: '',
        // priority: 0,
        contentRichText: '',
        contentRichTextUrl: '',
        link: '',
        production: ''
      },
      rules: {
        title: [
          { required: true, message: '请填写广告标题', trigger: 'change' },
          { min: 3, max: 32, message: '广告标题字符数限定3～32个', trigger: 'change' }
        ],
        cover: [{ required: true, message: '请上传广告封面', trigger: 'change' }],
        status: [{ required: true, message: '请选择广告状态', trigger: 'change' }],
        type: [
          { required: true, message: '请选择广告类型', trigger: 'change' }
        ],
      }
    }
  },
  components: { QuillEditor },
  created() {
    this.handleInitial()
    this.$form.createForm(this)
  },
  activated() {
    this.handleInitial()
    this.$form.createForm(this)
    // debugger;
  },
  methods: {
    handleSearch(keywords) {
      if (this.form.type == 2) {
        queryProductions({ keywords: keywords, current: 1, size: 1000, status: 'online' }).then(result => {
          this.prodList = result.records
        })
      }
    },
    handleChange(value) {
      this.form.production = value
      return true
    },
    async handleUpload(event) {
      this.loading = true
      let result = await client.put('advertise/' + event.file.uid, event.file)
      this.form.cover = result.url
      this.loading = false
      this.$refs.form.clearValidate()
    },
    async handleQuillChange(html) {
      this.form.contentRichText = html
    },
    handleReset(e) {
      this.$refs.form.resetFields()
    },
    handleCancel(e) {
      this.$router.back()
    },
    handleInitial() {
      const { id } = this.$route.query
      if (id) {
        getMiniAd(this.$route.query).then(result => {
          this.form.type = result.type;
          this.$nextTick(() => {
            this.form = Object.assign({}, this.form, result)
          })
        })
      }
      else{
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      }
    },
    async handleSubmit(e) {
      e.preventDefault()

      if (this.form.contentRichText) {
        const path = 'advertise/' + this.uuid + '.html'
        const result = await client.putStream(path, fs(combine(this.form.contentRichText), { path: path }))
        this.form.contentRichTextUrl = result.url
      }


      this.$refs.form.validate((result) => {
        if (result) {
          switch (Number(this.form.type)) {
            case 1:
              if (!this.form.link) {
                this.$message.warning('请输入链接');
                return;
              }
              break;
            case 2:
            case 5:  
              if (!this.form.production) {
                this.$message.warning('请选择商品');
                return;
              }
              break;
            case 3:  
              if (!this.form.contentRichText) {
                this.$message.warning('请输入内容');
                return;
              }
              break;              
            default:
              break;
          }

          putMiniAd(this.form).then(res => {
            this.$router.back()
          })
        }
      })
    }

  }
}
</script>
